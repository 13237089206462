import React, { memo } from 'react';
import logo from 'images/logo.svg';
import { Icon } from '@just-ai/just-ui';

import { t } from 'localization';

import { SelectAccountButton } from 'components/SelectAccountButton';
import { appStores } from 'store';

import UserPanel from './UserPanel';

import styles from './styles.module.scss';

const AppHeader = memo(() => {
  const { user, logout } = appStores.CurrentUser(store => ({
    user: store.user,
    logout: store.logout,
  }));

  return (
    <div className={styles.AppHeader}>
      <div className='flex items-center gap-24'>
        <div className={styles.title}>
          <img width={40} height={40} src={logo} alt='logo' />
          <span style={{ lineHeight: '40px' }}>{t('App:Title')}</span>
        </div>
        <SelectAccountButton />
      </div>
      <div className={styles.rightPanel}>
        <a href='https://help.jaycopilot.com/jayguard/' className={styles.documents} target='_blank' rel='noreferrer'>
          <div className='flex items-center gap-8'>
            <Icon name='farQuestionCircle' />
            {t('App:Documentation')}
          </div>
        </a>
        {user && <UserPanel user={user} logout={logout} />}
      </div>
    </div>
  );
});

AppHeader.displayName = 'AppHeader';

export default AppHeader;
