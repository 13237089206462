import React, { FC, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { ApiKeyApi, AuditApi, FilterApi, NamedEntityApi, PlaygroundApi } from '@just-ai/api/dist/generated/DataFilter';
import { Spinner } from '@just-ai/just-ui';

import { axios } from 'utils/network/axios';

import AppHeader from 'components/AppHeader';
import { appStores } from 'store';

import styles from './styles.module.scss';

interface ServiceContextProvider {
  filtersApi: FilterApi;
  entityApi: NamedEntityApi;
  apiKeyApi: ApiKeyApi;
  auditApi: AuditApi;
  playgroundApi: PlaygroundApi;
}

const ServiceContext = React.createContext({} as ServiceContextProvider);
export const useServiceContext = () => useContext(ServiceContext);

export const AppServicesProvider: FC = ({ children }) => {
  return (
    <ServiceContext.Provider
      value={{
        filtersApi: useMemo(() => new FilterApi({}, '', axios), []),
        entityApi: useMemo(() => new NamedEntityApi({}, '', axios), []),
        apiKeyApi: useMemo(() => new ApiKeyApi({}, '', axios), []),
        auditApi: useMemo(() => new AuditApi({}, '', axios), []),
        playgroundApi: useMemo(() => new PlaygroundApi({}, '', axios), []),
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export const FullContainer: FC = memo(({ children }) => {
  const { loadCurrentUser, user, isUserLoading, loadCurrentAccount, loadCloudOptions, ccUserLoading } =
    appStores.CurrentUser(store => ({
      loadCurrentUser: store.loadCurrentUser,
      user: store.user,
      isUserLoading: store.isUserLoading,
      loadCurrentAccount: store.getCurrentAccount,
      loadCloudOptions: store.getCloudOptions,
      ccUserLoading: store.ccUserLoading,
    }));
  const { loadEntities } = appStores.Entities(store => ({ loadEntities: store.loadEntities }));
  const { loadFilters } = appStores.FiltersStore(store => ({ loadFilters: store.loadFilters }));
  const { loadApiKeys } = appStores.ApiKeysStore(store => ({ loadApiKeys: store.loadApiKeys }));

  const getData = useCallback(async () => {
    await loadCloudOptions();
    await loadCurrentAccount();
    loadCurrentUser();
    loadEntities();
    loadFilters();
    loadApiKeys();
  }, [loadApiKeys, loadCloudOptions, loadCurrentAccount, loadCurrentUser, loadEntities, loadFilters]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (isUserLoading || !user || ccUserLoading) return <Spinner size='4x' />;

  return (
    <div className={styles.FullContainer}>
      <AppServicesProvider>
        <AppHeader />
        <div style={{ flex: 1, minHeight: 0 }}>{children}</div>
      </AppServicesProvider>
    </div>
  );
});
FullContainer.displayName = 'memo(FullContainer)';
