import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { isDev } from 'utils/constants';
import { getUserLanguage } from 'utils/language';
import { redirectToSelectAccount } from 'utils/redirect';

const timezone = new Date().getTimezoneOffset();
let headers = {
  language: getUserLanguage(),
  Product: 'dataFilter',
  Timezone: timezone,
};

let axiosWithSessionCheck = axios.create({
  headers,
  validateStatus: status => {
    if (status === 401) {
      window.location.href = isDev()
        ? `//localhost:3001/c/login?redirectUrl=${window.location.href}`
        : `/c/login?redirectUrl=${window.location.href}`;

      return false;
    }
    if (status === 403) {
      redirectToSelectAccount();

      return false;
    }
    return status >= 100 && status < 300;
  },
});

//@ts-ignore
axiosWithSessionCheck._get = (url: string, options: AxiosRequestConfig) =>
  new Promise((resolve, reject) => {
    axiosWithSessionCheck
      .get(url, options)
      .then(response => {
        response.config = { ...response.config, ...options };
        resolve(response);
      })
      .catch(reject);
  });

const postPutDeleteInterceptor =
  (method: 'post' | 'put' | 'patch' | 'delete') => (url: string, data: any, config: AxiosRequestConfig) =>
    new Promise((resolve, reject) => {
      //@ts-ignore
      axiosWithSessionCheck[method](url, data, config)
        .then((response: AxiosResponse) => {
          response.config = { ...response.config, ...config };
          resolve(response);
        })
        .catch(reject);
    });

//@ts-ignore
axiosWithSessionCheck._post = postPutDeleteInterceptor('post');
//@ts-ignore
axiosWithSessionCheck._put = postPutDeleteInterceptor('put');
//@ts-ignore
axiosWithSessionCheck._delete = postPutDeleteInterceptor('delete');

export const setAxiosHeadersLanguage = (val: string) => {
  axiosWithSessionCheck.defaults.headers.language = val;
};

export const updateLanguage = (language: string) =>
  setAxiosHeadersLanguage(language?.substr(0, 2).toUpperCase() || 'en');

export const isAxiosError = (e: any): e is AxiosError<any> => {
  return e?.response?.data;
};

export const getNetworkError = (e: any) => {
  if (!isAxiosError(e)) return;
  return {
    code: e?.response?.data?.error,
    message: e?.response?.data?.message,
  };
};

export { axiosWithSessionCheck as axios };
