export const commonLocalization = {
  cancel: {
    ru: 'Отмена',
    eng: 'Cancel',
  },
  save: {
    ru: 'Сохранить',
    eng: 'Save',
  },
  change: {
    ru: 'Изменить',
    eng: 'Change',
  },
  Apply: {
    ru: 'Применить',
    eng: 'Apply',
  },
  Reset: {
    ru: 'Сбросить',
    eng: 'Reset',
  },
  close: {
    ru: 'Закрыть',
    eng: 'Close',
  },
  Copy: {
    ru: 'Копировать',
    eng: 'Copy',
  },
  Delete: {
    ru: 'Удалить',
    eng: 'Delete',
  },
  'Pagination:Founded': {
    eng: 'Found: {size}',
    ru: 'Найдено: {size}',
  },
  'Pagination:ToThePage': {
    eng: 'To page',
    ru: 'К странице',
  },
  'Pagination:ShowSize': {
    eng: 'Display',
    ru: 'Показать',
  },
  'App:Title': {
    ru: 'Jay Guard',
    eng: 'Jay Guard',
  },
  'App:Documentation': {
    ru: 'Документация',
    eng: 'Documentation',
  },
  'Sidebar: accessKeys': {
    ru: 'Ключи доступа',
    eng: 'Access keys',
  },
  'Sidebar: userRules': {
    ru: 'Пользовательские сущности',
    eng: 'Custom entities',
  },
  'Sidebar: filters': {
    ru: 'Фильтры',
    eng: 'Filters',
  },
  'Sidebar: audit': {
    ru: 'Аудит',
    eng: 'Audit',
  },
  'Sidebar: playground': {
    ru: 'Тестирование',
    eng: 'Playground',
  },
  'Required field': {
    ru: 'Обязательное поле',
    eng: 'Required field',
  },
  Logout: {
    ru: 'Выйти',
    eng: 'Sign out',
  },
};
