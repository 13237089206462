import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Button } from '@just-ai/just-ui';

import localize, { t } from 'localization';

import { appStores } from 'store';

import { selectAccountButtonLocalization } from './localization/selectAccountButton.loc';

import styles from './styles.module.scss';

localize.addTranslations(selectAccountButtonLocalization);

export const SelectAccountButton = () => {
  const { currentUserAllowedAccounts, invitations, ccUserData } = appStores.CurrentUser(store => ({
    invitations: store.invitations,
    ccOptions: store.ccOptions,
    currentUserAllowedAccounts: store.ccUserAllowedAccounts,
    ccUserData: store.ccUserData,
  }));

  const goToSelectAccount = useCallback(() => {
    window.location.href = `${window.location.origin}/c/select-account`;
  }, []);

  const availableAccountsToChange = useMemo(() => {
    return currentUserAllowedAccounts?.filter(account => account.availableToSelect);
  }, [currentUserAllowedAccounts]);

  const currentAccount = currentUserAllowedAccounts?.find(data => data.id === ccUserData?.userData?.accountId);

  if (!availableAccountsToChange || availableAccountsToChange.length <= 1) return null;

  return (
    <div className={styles.SelectAccount}>
      <Button
        outline
        color='secondary'
        className={cn(styles.SelectAccount__button, { [styles.notification]: Boolean(invitations?.length) })}
        iconRight='farArrowRight'
        onClick={goToSelectAccount}
        data-test-id='goToAccountSelect'
      >
        <small className={styles.SelectAccount__text}>
          {t('SelectAccount', { currentAccountName: currentAccount?.name })}
        </small>
      </Button>
    </div>
  );
};
