import { create } from 'zustand';
import { ApiKeyApi, ApiKeyResponse } from '@just-ai/api/dist/generated/DataFilter';

import { axios } from 'utils/network/axios';

export const apiKeyApi = new ApiKeyApi({}, '', axios);

export type ApiKeysState = {
  apiKeys: ApiKeyResponse[];
  loadApiKeys: () => Promise<void>;
  isLoaded: Boolean;
};

export const useApiKeysStore = create<ApiKeysState>(set => ({
  apiKeys: [],
  async loadApiKeys() {
    const res = await apiKeyApi.findAll2();
    return set({ apiKeys: res.data.content, isLoaded: true });
  },
  isLoaded: false,
}));
