import React from 'react';
import ReactDOM from 'react-dom';

import localize from 'localization';

import App from './App';

import './services/logger';

localize.setLocale('ru');

ReactDOM.render(<App />, document.getElementById('root'));
