import { useApiKeysStore } from './slices/apiKeys';
import { useCurrentUser } from './slices/currentUser';
import { useEntities } from './slices/entities';
import { useFiltersStore } from './slices/filters';
import { useUserEntities } from './slices/userEntities';

export const appStores = {
  CurrentUser: useCurrentUser,
  Entities: useEntities,
  UserEntities: useUserEntities,
  FiltersStore: useFiltersStore,
  ApiKeysStore: useApiKeysStore,
};

Object.freeze(appStores);
