import React from 'react';
import { NavLink } from 'react-router-dom';

import { t } from 'localization';

import { getMenuItems } from 'routes';

import './index.scss';

const Sidebar = () => {
  return (
    <div className='sidebar-wrapper'>
      <div className='sidebar'>
        {getMenuItems().map(sidebarItem => (
          <div
            key={`sidebarItem_${sidebarItem.title}`}
            className='sidebar-item'
            data-test-id={`navigation.${sidebarItem.title}`}
          >
            <NavLink to={sidebarItem.link} className='w-full'>
              {t(`Sidebar: ${sidebarItem.title}`)}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
