import { create } from 'zustand';
import { FilterApi, PageableResponseFilterDto } from '@just-ai/api/dist/generated/DataFilter';

import { axios } from 'utils/network/axios';

const filterApi = new FilterApi({}, '', axios);

export type FiltersState = {
  filters: PageableResponseFilterDto['content'];
  paging: PageableResponseFilterDto['paging'];
  loadFilters: () => Promise<void>;
};

export const useFiltersStore = create<FiltersState>(set => ({
  filters: [],
  paging: {
    pageNum: 0,
    pageSize: 100,
    totalCount: -1,
  },
  // TODO forgotten paging
  async loadFilters() {
    const res = await filterApi.findAll1(0, 100);
    return set({ filters: res.data.content, paging: res.data.paging });
  },
}));
