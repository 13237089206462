import { create } from 'zustand';
import { NamedEntityApi, UserEntityListDto } from '@just-ai/api/dist/generated/DataFilter';

import { axios } from 'utils/network/axios';

const entityApi = new NamedEntityApi({}, '', axios);

export type UserEntitiesState = {
  userEntities: UserEntityListDto['entities'];
  loadUserEntities: () => Promise<void>;
};

export const useUserEntities = create<UserEntitiesState>(set => ({
  userEntities: [],
  lang: 'eng',
  async loadUserEntities() {
    const res = await entityApi.findAllUserEntities();
    return set({ userEntities: res.data.entities });
  },
}));
