import { isDev } from './constants';

export const redirectToSelectAccount = () => {
  window.location.href = isDev()
    ? `https://localhost:3001/c/select-account`
    : `${window.location.origin}/c/select-account`;
};

export const ccLogout = () => {
  window.location.href = isDev()
    ? `https://localhost:3001/c/logout`
    : `${window.location.origin}/c/logout?redirectUrl=${window.location.origin}`;
};
