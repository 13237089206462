import { create } from 'zustand';
import { EntityListDto, NamedEntityApi } from '@just-ai/api/dist/generated/DataFilter';

import localize from 'localization';
import { axios } from 'utils/network/axios';

const entityApi = new NamedEntityApi({}, '', axios);

export type RuleTriggersState = {
  systemEntities: EntityListDto['systemEntities'];
  userEntities: EntityListDto['userEntities'];
  loadEntities: () => Promise<void>;
};

export const useEntities = create<RuleTriggersState>(set => ({
  systemEntities: [],
  userEntities: [],
  lang: 'eng',
  async loadEntities() {
    const {
      data: { systemEntities, userEntities },
    } = await entityApi.findAllEntities();
    const localizationToAdd = systemEntities.reduce((acc, currItem) => {
      let localizationObj: Record<string, string> = {};
      Object.keys(currItem.localization || {}).forEach(key => {
        if (currItem.localization?.[key]) {
          localizationObj[key.toLowerCase()] = currItem.localization?.[key];
        }
      });
      acc[`SystemEntity:${currItem.entityKey}`] = localizationObj;
      return acc;
    }, {} as Record<string, Record<'ru' | 'eng', string>>);

    localize.addTranslations(localizationToAdd);
    return set({ systemEntities: systemEntities, userEntities: userEntities });
  },
}));
