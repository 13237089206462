import { toast } from 'react-toastify';
import { LogLevel, LogTransport, RichLogEvent } from '@just-ai/logger';

import { tWithCheck } from 'localization';
import { getNetworkError } from 'utils/network/axios';

export class ToastLogger implements LogTransport {
  level = LogLevel.LOG;

  log(event: RichLogEvent, context?: Record<string, any> & { forToast?: boolean }) {
    if (!context?.forToast) return;

    const networkError = getNetworkError(event.exception);
    const errorText = tWithCheck(networkError?.code) || networkError?.message || event.message;

    switch (event.level) {
      case LogLevel.FATAl:
        toast.error(errorText, { autoClose: 4_000 });
        break;
      case LogLevel.ERROR:
        toast.error(errorText, { autoClose: 3_000 });
        break;
      case LogLevel.WARN:
        toast.warn(event.message, { autoClose: 3_000 });
        break;
      case LogLevel.LOG:
        toast.info(event.message, { autoClose: 1_000 });
        break;
      default:
        break;
    }
  }
}
