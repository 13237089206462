import { mapKeys } from 'lodash';

import { commonLocalization } from './common.loc';
import { errorsLocalization } from './errors.loc';

const Localize = require('localize');

const localize = new Localize({
  ...commonLocalization,
  ...errorsLocalization,
});

localize.addTranslations = translate => {
  mapKeys(translate, key => {
    if (!localize.getTranslations().hasOwnProperty(key)) {
      localize.loadTranslations(translate);
    }
  });
};

localize.checkExistKey = key => key !== localize.translate(key);

export const engT = (...args) => {
  const initialLocale = localize.getLocale();
  localize.setLocale('eng');
  const returnValue = localize.translate(...args);
  localize.setLocale(initialLocale);
  return returnValue;
};

export const { translate: t } = localize;

export default localize;

export const tWithPlural = localize.translateWithPlural;

export const tWithCheck = (...args) => {
  const translated = t(...args);
  if (translated === args[0]) {
    return '';
  }
  return translated;
};

export const getLocaleKeyForDto = () => (localize.getLocale() === 'eng' ? 'EN' : localize.getLocale().toUpperCase());
